import { ReconnectingAnimation } from "@/components/shared/Loaders/ReconnectingAnimation";
import { router } from "@/router";
import { getDefaultStore } from "jotai";
import { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { baseWebGwUrl } from "..";
import { defaultPage, paths } from "../../routerPaths";
import { delay } from "../helpers/Utils";
import { handleCapabilitiesNotifications } from "../helpers/WebGwContactCapabilities";
import { atoms } from "../helpers/atoms";
import { getConfig } from "../helpers/config";
import {
  clearLocalAndSessionStorage,
  getLocalAccessToken,
  getLocalUser,
  getLoggedOutFromUser,
  getPassword,
  getSipUser,
  getUsername,
  setLocalAccessToken,
} from "../helpers/localstorage";
import { login } from "../helpers/loginAndCaps";
import { handleSessionExpired } from "../helpers/loginAndCaps/session";
import {
  CapabilityNotification,
  ChatMessageStatusNotification,
  ClientStateNotification,
  ComposingNotification,
  GroupChatIconNotification,
  NewGroupChatInvitationNotification,
  NewMessageNotification,
  NotificationChannelManager,
  NotificationListener,
  RegisterListener,
  WebRTCAnswerNotification,
  WebRTCCVONotification,
  WebRTCPauseNotification,
  WebRTCStatusUpdateNotification,
  notificationChannel,
  notificationListenerType,
  registerListenerType,
} from "../helpers/notificationChannel";
import {
  handleGroupChatIcon,
  handleIsComposing,
  handleMessageStatusNotification,
  handleNewChatMessage,
  handleNewGroupChatInvitation,
} from "../messaging/conversation/conversationUtils/";
import { loginQuery } from "../queries/login";
import { queryClient } from "../queryClient";
import { handleWebRTC } from "../webrtc/webrtcUtils";

let notificationChannelManager: NotificationChannelManager | undefined =
  undefined;

/**
 * Should only be called once
 * @param getAccessToken
 * @param getUser
 * @param callbackURL
 */
export default function useWebgwSubscription(forceNewChannel = false) {
  const subscribed = useRef(false);
  useEffect(() => {
    if (subscribed.current && !forceNewChannel) return;

    reSub(getLocalAccessToken());

    subscribed.current = true;
  }, []);
}

/**
 *  ```
 *  const notificationHandler: NotificationListener = (e) => {
 *    console.debug(e);
 *  };
 *  useWebgwNotifications(notificationHandler);
 * ```
 *
 * @param notificationHandler
 */
export function useWebgwNotifications(
  notificationHandler: NotificationListener
) {
  useEffect(() => {
    notificationChannel.addEventListener(
      notificationListenerType,
      notificationHandler
    );
    return () => {
      if (import.meta.env.DEV)
        console.debug("removing WebgwNotification event listener");
      notificationChannel.removeEventListener(
        notificationListenerType,
        notificationHandler
      );
    };
  }, [notificationHandler]);
}

/*
  Each of these hooks could have their own EventTarget rather than every one of their inner callbacks
  running when a notification comes in, but that's a micro optimization
*/

export type NewMessageHandler = (n: NewMessageNotification) => void;
export function useWebgwChatMessage(handler: NewMessageHandler) {
  useWebgwNotifications(({ detail: notificationRes }) => {
    if ("notificationList" in notificationRes) {
      if (Array.isArray(notificationRes.notificationList)) {
        for (const notification of notificationRes.notificationList) {
          if ("chatMessageNotification" in notification) {
            const chatNotification = notification.chatMessageNotification;

            if ("chatMessage" in chatNotification) {
              handler(chatNotification);
            }
          }
        }
      }
    }
  });
}

export type NewGroupChatInvitationHandler = (
  n: NewGroupChatInvitationNotification
) => void;
export function useWebgwGroupChatInvitation(
  handler: NewGroupChatInvitationHandler
) {
  useWebgwNotifications(({ detail: notificationRes }) => {
    if ("notificationList" in notificationRes) {
      if ("groupchatNotification" in notificationRes.notificationList) {
        if (
          "invite_received" in
          notificationRes.notificationList.groupchatNotification
        ) {
          handler(notificationRes.notificationList.groupchatNotification);
        }
      }
    }
  });
}

export type GroupChatIconHandler = (n: GroupChatIconNotification) => void;
export function useWebgwGroupChatIcon(handler: GroupChatIconHandler) {
  useWebgwNotifications(({ detail: notificationRes }) => {
    if ("notificationList" in notificationRes) {
      if ("groupchatNotification" in notificationRes.notificationList) {
        if (
          "set_icon" in notificationRes.notificationList.groupchatNotification
        ) {
          handler(notificationRes.notificationList.groupchatNotification);
        }
      }
    }
  });
}

export type CapabilityHandler = (n: CapabilityNotification) => void;
export function useWebgwCapability(handler: CapabilityHandler) {
  useWebgwNotifications(({ detail: notificationRes }) => {
    if ("notificationList" in notificationRes) {
      if (Array.isArray(notificationRes.notificationList)) {
        for (const notification of notificationRes.notificationList) {
          if ("contactServiceCapabilities" in notification) {
            handler(notification);
          }
        }
      }
    }
  });
}

export type NewWebRTCMessageHandler = (
  n:
    | WebRTCPauseNotification
    | WebRTCStatusUpdateNotification
    | WebRTCAnswerNotification
    | WebRTCCVONotification
) => void;
export function useWebgwWrtcMessage(handler: NewWebRTCMessageHandler) {
  useWebgwNotifications(({ detail: notificationRes }) => {
    if ("notificationList" in notificationRes) {
      const notificationList = notificationRes.notificationList;
      if ("summitCallNotification" in notificationList) {
        const wrtcNotification = notificationList.summitCallNotification;
        handler(wrtcNotification);
      }
    }
  });
}

export type ComposingHandler = (n: ComposingNotification) => void;
export function useWebgwIsComposing(handler: ComposingHandler) {
  useWebgwNotifications(({ detail: notificationRes }) => {
    if ("notificationList" in notificationRes) {
      if (Array.isArray(notificationRes.notificationList)) {
        for (const notification of notificationRes.notificationList) {
          if ("chatMessageNotification" in notification) {
            const chatNotification = notification.chatMessageNotification;

            if ("isComposing" in chatNotification) {
              handler(chatNotification);
            }
          }
        }
      }
    }
  });
}

export type StatusNotificationHandler = (
  n: ChatMessageStatusNotification["chatMessageStatusNotification"]
) => void;
export function useWebgwStatusNotification(handler: StatusNotificationHandler) {
  useWebgwNotifications(({ detail: notificationRes }) => {
    if ("chatMessageStatusNotification" in notificationRes) {
      handler(notificationRes.chatMessageStatusNotification);
    }
  });
}

export type ClientStateNotificationHandler = (
  n: ClientStateNotification["clientStateNotification"]
) => void;
export function useWebgwClientStateNotification(
  handler: ClientStateNotificationHandler
) {
  useWebgwNotifications(({ detail: notificationRes }) => {
    if ("notificationList" in notificationRes) {
      if (!Array.isArray(notificationRes.notificationList)) {
        if ("clientStateNotification" in notificationRes.notificationList) {
          handler(notificationRes.notificationList.clientStateNotification);
        }
      }
    }
  });
}
function handleClientStateNotification(
  clientStateNotification: ClientStateNotification["clientStateNotification"]
) {
  console.log("clientStateNotification:", clientStateNotification);
  const loggedOut = defaultStore.get(atoms.provisioning.reconnectingAtom);
  if (clientStateNotification.state === 256) {
    defaultStore.set(atoms.provisioning.isLoggedIn, true);
  } else {
    defaultStore.set(atoms.provisioning.isLoggedIn, false);
  }
  if (clientStateNotification.state === 50) {
    if (getLoggedOutFromUser()) {
      clearLocalAndSessionStorage();
      window.location.replace("");
    }
    // Kicked out by server, in our current flow mostly from ACS subscriber deletion
    else {
      console.log("Logged out automatically from server");
      handleReconnectionFailure();
    }
  } else if (
    clientStateNotification.state === 0 &&
    notificationChannelManager &&
    !loggedOut
  ) {
    // We do not need to do the whole logic located inside useLogin since we already loaded the layout.
    // We only want to start the login/relogin mechanism.
    queryClient.fetchQuery(loginQuery);
  }
}

/**
 *  ```
 *  const registerHandler: RegisterHandler = (e) => {
 *    console.debug(e);
 *  };
 *  useWebgwRegister(registerHandler);
 * ```
 *
 * @param registerHandler
 */
export function useWebgwRegister(registerHandler: RegisterListener) {
  useEffect(() => {
    notificationChannel.addEventListener(registerListenerType, registerHandler);
    return () => {
      if (import.meta.env.DEV)
        console.debug("removing WebgwRegister event listener");
      notificationChannel.removeEventListener(
        registerListenerType,
        registerHandler
      );
    };
  }, [registerHandler]);
}

export type RegisterHandler = (n: boolean) => void;
export function useWebgwRegisterNotification(handler: RegisterHandler) {
  useWebgwRegister(({ detail: notificationRes }) => {
    handler(notificationRes);
  });
}

let doingRelogin: boolean = false;

const defaultStore = getDefaultStore();
let reconnectingTimeout: number | undefined;

async function handleReconnectionSuccess() {
  doingRelogin = false;
  clearTimeout(reconnectingTimeout);
  defaultStore.set(atoms.provisioning.reconnectingAtom, false);
  defaultStore.set(atoms.provisioning.isLoggedIn, true);
  getConfig();
}

function handleReconnectionFailure() {
  notificationChannelManager?.close();
  notificationChannelManager?.logout();
  doingRelogin = false;
  defaultStore.set(atoms.provisioning.reconnectingAtom, true);
  defaultStore.set(atoms.provisioning.isLoggedIn, false);
  clearLocalAndSessionStorage();
  handleSessionExpired();

  // Cancel and clear cache of any existing queries
  queryClient.cancelQueries();
  queryClient.clear();

  router.navigate(paths.provisioning);
}

export async function handleReloginMechanic(registerNotification: boolean) {
  console.log(
    `handleReloginMechanic: registerNotification=${registerNotification}, doingRelogin=${doingRelogin}`
  );
  if (!registerNotification && !doingRelogin) {
    doingRelogin = true;
    defaultStore.set(atoms.provisioning.reconnectingAtom, true);
    const retryCount = 5;
    const reconnected = await exponentialBackoff(retryLogin, retryCount, 2500);
    if (!reconnected) {
      console.log(`Not reconnected after retried login ${retryCount} times`);
      await handleReconnectionFailure();
    } else {
      await handleReconnectionSuccess();
    }
  } else if (registerNotification) {
    console.log("Registered");
    await handleReconnectionSuccess();
  }
}

async function handleRegisterNotification(registerNotification: boolean) {
  console.warn(
    "registerNotification:",
    registerNotification,
    ", doingRelogin:",
    doingRelogin
  );
  if (registerNotification) {
    defaultStore.set(atoms.provisioning.isLoggedIn, true);
    console.log("Successful connection");
    return;
  }
  // First try to login again, if it fails, only then will we "relogin".
  const accessToken = getLocalAccessToken();
  if (accessToken) {
    try {
      const loginRes = await login(accessToken);
      console.log(`Login response -> ${loginRes?.status}`);
      const success = !!loginRes?.ok;
      defaultStore.set(atoms.provisioning.isLoggedIn, success);
      if (!success) {
        console.log(`Failed to login, calling Relogin`);
        await handleReloginMechanic(registerNotification);
      } else {
        reSub(accessToken);
      }
    } catch (error) {
      console.error(`Error during login: ${error}`);
    }
  } else {
    await handleReloginMechanic(registerNotification);
  }
}

async function exponentialBackoff(
  operation: () => Promise<boolean>,
  maxRetries: number,
  initialDelay: number
): Promise<boolean> {
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    console.debug("exponentialBackoff: attempt=", attempt, "/", maxRetries);
    try {
      // Always verify first if we are currently connected, as race conditions are possible
      console.debug(
        "exponentialBackoff: reconnecting=",
        defaultStore.get(atoms.provisioning.reconnectingAtom)
      );
      if (!defaultStore.get(atoms.provisioning.reconnectingAtom)) {
        console.log("Got REGISTERED while retrying, stop");
        return true;
      }

      const delay =
        initialDelay * Math.pow(2, attempt - 2) + Math.random() * 5000; //delay for first time too using half initialDelay
      await new Promise((resolve) => setTimeout(resolve, delay));
      //await delay(delay);
      if (!defaultStore.get(atoms.provisioning.reconnectingAtom)) {
        console.log("Got REGISTERED while retrying, stop");
        return true;
      }

      const myOperation = operation();
      const toastId = "relogin";
      if (attempt >= 2) {
        toast.loading("Reconnecting to server, please wait...", {
          style: { backgroundColor: "#2E3237", color: "#FFFFFF" },
          icon: <ReconnectingAnimation />,
          id: toastId,
        });
      }

      const success = await myOperation;
      console.debug("exponentialBackoff: myOperation=", success);
      if (success) {
        console.log("Successfully reconnected");
        return true;
      }
      toast.dismiss(toastId);
    } catch (exception: any) {
      if (exception?.message === "404") {
        console.error("Device has been removed from ACS");
        await handleReconnectionFailure();
        return false;
      } else {
        console.warn("exception: ", exception);
      }
    }

    if (attempt === maxRetries) {
      return false;
    }
    console.log(
      `Attempt ${attempt} failed. Retrying in ${
        initialDelay * Math.pow(2, attempt - 1)
      } ms`
    );
  }
  return false;
}

export async function retryLogin() {
  const sipUser = getSipUser();
  const username = getUsername();
  const password = getPassword();
  if (
    sipUser &&
    username &&
    password &&
    sipUser.length > 0 &&
    username.length > 0 &&
    password.length > 0
  ) {
    console.log("retryLogin(maybe stop if Got REGISTERED");
    const response = await fetch(
      new URL(
        `/client/v1/relogin?_t=${Date.now()}&resFormat=json`,
        baseWebGwUrl
      ),
      {
        method: "POST",
        referrerPolicy: "no-referrer-when-downgrade",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        credentials: "include",
        cache: "no-store",
        body: JSON.stringify({
          uri: sipUser,
          username: username,
          password: password,
        }),
      }
    );
    if (response.status === 404) {
      throw new Error("404");
    }
    const data = await response.json();
    if (
      data?.notificationList?.loginResultNotification?.result === "otprequired"
    ) {
      localStorage.setItem(
        "uriBeforeRelogin",
        window.location.href.split("#").pop() ?? defaultPage
      );
      console.warn("redirect(paths.provisioning)"); //hook conflict
      window.location.href = "#" + paths.provisioning;
      return true; // don't getconfig as still provisioning
    }
    await reSub(
      data?.notificationList?.loginResultNotification?.access_token,
      true
    );
    return (await getConfig()) != null;
  } else {
    return false;
  }
}

async function reSub(accessToken: string | null, force = false) {
  console.log("accessToken:", accessToken);
  if (!accessToken) {
    console.warn(
      "Failed to fetch AccessToken from relogin response, bad response..."
    );
    return false;
  }
  if (force) {
    console.log("forcing new channel with new access_token:", accessToken);
    setLocalAccessToken(accessToken);
  }

  const user = getLocalUser();
  console.log("accessToken:", accessToken, ", user:", user);
  if (!accessToken || !user) {
    return false;
  }

  if (notificationChannelManager) notificationChannelManager.close();
  notificationChannelManager = new NotificationChannelManager();
  console.log("Subscribing to WebGW");
  await delay(20); //wait to avoid 1ms race cond in server side
  await notificationChannelManager.subscribe(accessToken, user, "");

  return true;
}

export function useAllWebGwNotifications() {
  useWebgwChatMessage(handleNewChatMessage);
  useWebgwGroupChatInvitation(handleNewGroupChatInvitation);
  useWebgwGroupChatIcon(handleGroupChatIcon);
  useWebgwStatusNotification(handleMessageStatusNotification);
  useWebgwIsComposing(handleIsComposing);
  useWebgwWrtcMessage(handleWebRTC);
  useWebgwClientStateNotification(handleClientStateNotification);
  useWebgwRegisterNotification(handleRegisterNotification);
  useWebgwCapability(handleCapabilitiesNotifications);
}
