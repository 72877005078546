import { DBSchema, IDBPDatabase, deleteDB, openDB } from "idb";
import { DOMContentLoaded, proxyToRaw, sleep } from ".";
import Call from "./calls/Call";
import { CallId, CallMap, callsState } from "./calls/callState";
import { getContactsAsync } from "./contacts/contactUtils";
import { WebGwContactList } from "./helpers/WebGwContact";
import { removeLocalMessagesCursor } from "./helpers/localstorage";
import Conversation from "./messaging/conversation/Conversation";
import {
  ConversationId,
  ConversationMap,
  conversationsState,
} from "./messaging/conversation/ConversationState";
import { deleteMessages } from "./messaging/deleteMessages";

interface DbSchema extends DBSchema {
  conversations: {
    key: ConversationId;
    value: ReturnType<(typeof Conversation)["prototype"]["serialize"]>;
  };
  calls: {
    key: CallId;
    value: ReturnType<(typeof Call)["prototype"]["serialize"]>;
  };
}

const dbName = "verse-nms-db";
const dbVersion = 2;

let deleteDbPromise: Promise<void> | null = null;
export async function deleteDbs() {
  // Make sure to clear the atoms associated with database tables
  callsState.calls.clear();
  conversationsState.conversations.clear();

  console.debug("deleting", dbName, window.getVerseDb(), deleteDbPromise);

  if (deleteDbPromise) {
    return deleteDbPromise;
  }

  // close the db first, making sure all transactions go through. this stops the blocking method from being called
  if (window.getVerseDb) {
    (await window.getVerseDb()).close();
    closeDb(await window.getVerseDb());
  }

  deleteDbPromise = new Promise<void>((res) => {
    deleteDB(dbName, {
      blocked: (currentVersion) => {
        console.warn("blocking in delete", currentVersion);
        res();
      },
    }).then(res);
    removeLocalMessagesCursor();
  }).finally(() => {
    console.info("Done deleting DB, delete db promise set to null");
    deleteDbPromise = null;
  });

  _verseDb = null;

  return deleteDbPromise;
}

async function createDb() {
  await deleteDbPromise;
  if (await DOMContentLoaded()) {
    await sleep(25);
  } else {
    await sleep(50);
  }
  console.info("Opening DB");
  const db = await openDB<DbSchema>(dbName, dbVersion, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("conversations")) {
        console.info("Creating conversation table");
        db.createObjectStore("conversations");
      }
      if (!db.objectStoreNames.contains("calls")) {
        console.info("Creating call table");
        db.createObjectStore("calls");
      }
    },
    blocked() {
      console.error("blocked on openDB.");
    },
    blocking() {
      console.error("blocking on openDB.", arguments);
    },
    terminated() {
      console.error("idb creation terminated.");
    },
  });
  return db;
}

function isDbClosed(db: IDBPDatabase<DbSchema>) {
  return !!db["_closed"]; // Using internal property of idb library
}
function closeDb(db: IDBPDatabase<DbSchema>) {
  db["_closed"] = true;
}

let _verseDb: Promise<IDBPDatabase<DbSchema>> | null = null;
declare global {
  interface Window {
    getVerseDb: () => Promise<IDBPDatabase<DbSchema>>;
  }
}
window.getVerseDb = async function (): Promise<IDBPDatabase<DbSchema>> {
  if (!_verseDb) {
    console.info("DB isn't created");
    _verseDb = createDb();
  }

  const db = await _verseDb;
  if (isDbClosed(db)) {
    console.info("Database is closed. Reopening...");
    _verseDb = createDb();
    return await _verseDb;
  }
  return db;
};

export async function getStoredConversations() {
  const conversations = new Map<ConversationId, Conversation>();
  const contactsPromise = getContactsAsync();
  let contacts: WebGwContactList;
  try {
    contacts = (await contactsPromise) || new WebGwContactList();
  } catch (e) {
    console.warn("contactsPromise failed", e);
    contacts = new WebGwContactList();
  }

  const tx = (await window.getVerseDb()).transaction("conversations");
  for await (const conversation of tx.store) {
    conversations.set(conversation.key, Conversation.from(conversation.value));
  }
  for (const convo of conversations) {
    //TODO change from id to looking for numbers for when group chat feature is introduced
    const phoneNumber = convo[0];
    const findContact = contacts.findWithNumber(phoneNumber);
    const phoneNumberExists = findContact
      ?.getAllPhoneNumbers()
      ?.includes(phoneNumber);
    if (phoneNumberExists && findContact) {
      convo[1].participants[0] = findContact;
    }
  }

  await tx.done;
  console.info("Loaded ", conversations.size, " conversations");
  return conversations.size ? conversations : undefined;
}

export async function getStoredCalls() {
  const calls = new Map<CallId, Call>();

  const tx = (await window.getVerseDb()).transaction("calls");
  for await (const call of tx.store) {
    calls.set(call.key, Call.from(call.value));
  }

  await tx.done;
  console.info("Loaded ", calls.size, " calls");
  return calls.size ? calls : undefined;
}

export async function updateStoredConversations(
  newConversations: ConversationMap
) {
  const db = await window.getVerseDb();

  // add the updated conversations to the idb
  const tx1 = db.transaction("conversations", "readwrite");
  await Promise.all(
    newConversations
      .entries()
      .map(([conversationId, conversation]) =>
        tx1.store.put(proxyToRaw(conversation.serialize()), conversationId)
      )
  );
  await tx1.done;
}

export async function updateStoredCalls(newCalls: CallMap) {
  const db = await window.getVerseDb();

  // add the updated conversations to the idb
  const tx2 = db.transaction("calls", "readwrite");
  await Promise.all(
    newCalls
      .entries()
      .map(([callId, call]) =>
        tx2.store.put(proxyToRaw(call.serialize()), callId)
      )
  );
  await tx2.done;
}

export async function deleteStoredConversation(conversation: Conversation) {
  const db = await window.getVerseDb();

  // delete the conversation from the idb
  const tx1 = db.transaction("conversations", "readwrite");
  await tx1.store.delete(conversation.id);
  await tx1.done;
}

export async function deleteStoredCall(callId: string) {
  const db = await window.getVerseDb();
  const tx2 = db.transaction("calls", "readwrite");
  await tx2.store.delete(callId);
  await tx2.done;

  callsState.calls.delete(callId);
  callsState.mapVersion++;
}

export async function deleteStoredCalls(callIds: string[]) {
  callIds.forEach((callId: string) => {
    deleteStoredCall(callId);
  });
}

export async function deleteConversation(
  conversation: Conversation
): Promise<boolean> {
  console.log("Deleting message inside conversation : ", conversation);
  let result: boolean | undefined = false;
  try {
    result = await deleteMessages(
      conversation.getMessages().map((nmsMessage) => {
        nmsMessage.destruct();
        return nmsMessage["imdn.Message-ID"];
      })
    );

    if (result) {
      deleteStoredConversation(conversation);
      conversationsState.conversations.delete(conversation.id);
    }

    console.log("Result deleting conversation -> ", result);
  } catch (err) {
    console.log("Error deleting conversation -> ", err);
  }

  return !!result;
}
