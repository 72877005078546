import {
  PopupOverlayBodyCSS,
  PopupOverlayButtonCSS,
  PopupOverlayCSS,
  PopupOverlayCloseButtonCSS,
} from "@/index.style";
import { paths } from "@/routerPaths";
import { clearSelection } from "@/utils";
import { atoms } from "@/utils/helpers/atoms";
import { useCall } from "@/utils/hooks/useCall";
import { useContacts } from "@/utils/hooks/useContacts";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { setSelectedConversationId } from "@/utils/messaging/conversation/ConversationState";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CallEndOutlinedIcon from "@mui/icons-material/CallEndOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import VideocamIcon from "@mui/icons-material/Videocam";
import {
  AnimatePresence,
  AnimationProps,
  MotionConfig,
  PanInfo,
  Point,
  cubicBezier,
  motion,
  useAnimationControls,
} from "framer-motion";
import { useSetAtom } from "jotai";
import { ReactNode, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "../../styles/global.styles";
import WebGwContact from "../../utils/helpers/WebGwContact";
import { Avatar, AvatarBackground } from "../shared/Avatar";
import { buttonCommon } from "../shared/Button";
import PopupOverlay from "../shared/PopupOverlay";
import { EndCallButtons } from "./Overlay.style";

export const overlayTransition: AnimationProps["transition"] = {
  duration: 0.25,
  ease: cubicBezier(0.25, 0.1, 0.25, 1),
};

interface OverlayProps {
  isFullScreen?: boolean;
  children: ReactNode;
  contact?: WebGwContact;
  onClick?: () => void;
  onMouseMove?: () => void;
  onDoubleClick: () => void;
  onBackgroundDoubleClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  open?: boolean;
}

interface SmallOverlayProps {
  children: ReactNode;
}

interface IncomingCallOverlayProps {
  isVideo: boolean;
  contact: WebGwContact;
  answerAudio: () => void;
  answerVideo: () => void;
  rejectCall: () => void;
}

let initialOverlayPosition: Point;

export function Overlay({
  onClick,
  isFullScreen,
  children,
  onMouseMove,
  onDoubleClick,
  onBackgroundDoubleClick,
  onMouseEnter,
  onMouseLeave,
  open,
}: OverlayProps) {
  const overlayRef = useRef<HTMLDivElement>(null!);

  const outerPaddingX = 0.03;
  const outerPaddingY = 0.05;
  const altPadding = 0.007;
  const dragConstraints = {
    get left() {
      return window.innerWidth * outerPaddingX + window.innerWidth * altPadding;
    },
    get right() {
      return window.innerWidth - overlayRef.current.clientWidth - this.left;
    },
    get top() {
      return (
        window.innerHeight * outerPaddingY + window.innerWidth * altPadding
      );
    },
    get bottom() {
      return window.innerHeight - overlayRef.current.clientHeight - this.top;
    },
  };
  initialOverlayPosition ??= {
    x: dragConstraints.left,
    y: dragConstraints.top,
  };

  const constrainPosition = (position: Point) => {
    position.x = Math.max(
      dragConstraints.left,
      Math.min(dragConstraints.right, position.x)
    );
    position.y = Math.max(
      dragConstraints.top,
      Math.min(dragConstraints.bottom, position.y)
    );
  };

  const animationControls = useAnimationControls();

  useEffect(() => {
    if (isFullScreen) {
      animationControls.set({
        x: "-50%",
        y: "-50%",
      });
      return;
    }

    const handleResize = () => {
      constrainPosition(initialOverlayPosition);
      animationControls.set({
        x: initialOverlayPosition.x,
        y: initialOverlayPosition.y,
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isFullScreen, open]);

  const handleDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    if (!isFullScreen) {
      initialOverlayPosition.x += info.offset.x;
      initialOverlayPosition.y += info.offset.y;
      constrainPosition(initialOverlayPosition);
    }
  };

  return (
    <MotionConfig transition={overlayTransition}>
      <motion.div
        ref={overlayRef}
        animate={animationControls}
        hidden={!open}
        onClick={onClick}
        onMouseMove={onMouseMove}
        onDoubleClick={(e) => {
          e.preventDefault();
          clearSelection();
          onDoubleClick();
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        drag={!isFullScreen}
        dragConstraints={dragConstraints}
        onDragEnd={handleDragEnd}
        dragElastic={0.2}
        dragTransition={{ timeConstant: 100, power: 0.05 }}
        css={[
          {
            position: "fixed",
            backfaceVisibility: "hidden",
            zIndex: "10000",
            overflow: "hidden",
            lineHeight: "1",
          },
          isFullScreen
            ? {
                maxWidth: "90vw",
                maxHeight: "90vh",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: colors.primaryBackgroundLighter,
                borderRadius: "20px",
              }
            : {
                top: "0",
                left: "0",
                cursor: "grab",
              },
        ]}
      >
        {children}
      </motion.div>
      <AnimatePresence>
        {isFullScreen && open && (
          <motion.div
            css={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: "9999",
              backgroundColor: "rgb(0,0,0,0.75)",
            }}
            onDoubleClick={(e) => {
              e.preventDefault();
              clearSelection();
              onBackgroundDoubleClick();
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          ></motion.div>
        )}
      </AnimatePresence>
    </MotionConfig>
  );
}

export function IncomingContactAvatar({ contact }: { contact: WebGwContact }) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "2em",
      }}
    >
      <Avatar
        contact={contact}
        style={
          contact.photo || !contact.initials
            ? { width: "8em", height: "8em" }
            : { height: "3.335em", width: "3.345em", fontSize: "3em" }
        }
      />
      <div
        css={{
          margin: "0",
          whiteSpace: "nowrap",
          fontSize: "2em",
        }}
      >
        {contact.noNameReturnPhoneNumber()}
      </div>
    </div>
  );
}

function SmallOverlay({ children }: SmallOverlayProps) {
  return (
    <motion.div
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
}

export function IncomingCallOverlay({
  isVideo,
  contact,
  answerAudio,
  answerVideo,
  rejectCall,
}: IncomingCallOverlayProps) {
  if (!contact) return null;

  return (
    <SmallOverlay>
      <div
        css={{
          width: "fit-content",
          height: "fit-content",
          backgroundColor: "#1A2026",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          color: "white",
          padding: "3vw",
        }}
      >
        <div>
          <IncomingContactAvatar contact={contact} />
        </div>

        {isVideo ? (
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.5em",
            }}
          >
            <div>Incoming Video Call</div>
            <VideocamIcon />
          </div>
        ) : (
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.5em",
            }}
          >
            <div>Incoming Call</div>
            <PhoneIcon />
          </div>
        )}

        <div
          css={{
            gap: "5em",
            display: "flex",
            flexDirection: "row",
            padding: "3vw",
          }}
        >
          <button onClick={rejectCall}>
            <AvatarBackground
              css={{
                backgroundColor: "#DC3851",
                width: "3em",
                height: "3em",
              }}
            >
              <CallEndOutlinedIcon
                css={{ color: "white", width: "1em", height: "1em" }}
              />
            </AvatarBackground>
          </button>

          <button onClick={answerAudio}>
            <AvatarBackground
              css={{
                backgroundColor: colors.secondaryAccentColor,
                width: "3em",
                height: "3em",
              }}
            >
              <PhoneIcon
                css={{ color: "white", width: "1em", height: "1em" }}
              />
            </AvatarBackground>
          </button>

          {isVideo && (
            <button onClick={answerVideo}>
              <AvatarBackground
                css={{
                  backgroundColor: colors.secondaryAccentColor,
                  width: "3em",
                  height: "3em",
                }}
              >
                <VideocamIcon
                  css={{ color: "white", width: "1em", height: "1em" }}
                />
              </AvatarBackground>
            </button>
          )}
        </div>

        {false && (
          <div
            css={{
              gap: "1em",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              width: "40vw",
              color: colors.secondaryTextColor,
            }}
          >
            {/* TO DO: ADD FUNCTIONALITY TO DISPLAY POP UP FOR REMIND ME */}
            <div
              css={{
                backgroundColor: "#2E3237",
                padding: "1em",
                borderRadius: "8px",
                width: "18vw",
                display: "flex",
                justifyContent: "center",
                gap: "1em",
                alignItems: "center",
              }}
            >
              <AccessTimeOutlinedIcon /> {"   "} Remind me
            </div>
            {/* TO DO: ADD FUNCTIONALITY TO DISPLAY POP UP FOR MESSAGES AND SEND MESSAGE TO INCOMING NUMBER */}
            <div
              css={{
                backgroundColor: "#2E3237",
                padding: "1em",
                borderRadius: "8px",
                width: "18vw",
                display: "flex",
                justifyContent: "center",
                gap: "1em",
                alignItems: "center",
              }}
            >
              <MessageOutlinedIcon /> {"   "}Reply With Message
            </div>
          </div>
        )}
      </div>
    </SmallOverlay>
  );
}

type UpgradeToVideoOverlayProps = {
  onAcceptVideoUpgrade: () => void;
  onDeclineVideoUpgrade: () => void;
};

export function UpgradeToVideoOverlay({
  onAcceptVideoUpgrade,
  onDeclineVideoUpgrade,
}: UpgradeToVideoOverlayProps) {
  return (
    <PopupOverlay>
      <div css={PopupOverlayCSS}>
        <div css={PopupOverlayBodyCSS}>
          <h2 css={{ margin: "0.83em 0" }}>Add Video</h2>
          <button
            css={PopupOverlayCloseButtonCSS}
            onClick={onDeclineVideoUpgrade}
          >
            <CloseIcon />
          </button>
        </div>

        <div css={{ marginBottom: "2vh", width: "100%" }}>
          Would you like to add video to the call?
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "row",
            gap: "1vw",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <button
            css={[
              PopupOverlayButtonCSS,
              {
                backgroundColor: colors.secondaryBackground,
                color: colors.primaryTextColor,
              },
            ]}
            style={{ width: "8em" }}
            onClick={onDeclineVideoUpgrade}
          >
            Decline
          </button>
          <button
            css={[
              PopupOverlayButtonCSS,
              {
                backgroundColor: "#DC3851",
                color: colors.primaryTextColor,
              },
            ]}
            style={{ width: "8em" }}
            onClick={onAcceptVideoUpgrade}
          >
            Add
          </button>
        </div>
      </div>
    </PopupOverlay>
  );
}

type CloseOverlayButtonProps = {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
};
function CloseOverlayButton({
  isFullScreen,
  toggleFullScreen,
}: CloseOverlayButtonProps) {
  return (
    <button
      onClick={toggleFullScreen}
      css={[
        buttonCommon,
        {
          color: colors.primaryTextColor,
          filter: `drop-shadow(0 0 4px rgb(0,0,0,0.25))`,
          background: colors.secondaryBackground,
          ":hover": { background: colors.secondaryBackgroundLighter },
        },
      ]}
    >
      <ExpandMoreIcon css={!isFullScreen && { transform: "rotate(180deg)" }} />
    </button>
  );
}

export function CloseOverlayButtonBox({
  isFullScreen,
  isHovered,
  toggleFullScreen,
  ...props
}: CloseOverlayButtonProps & { isHovered: boolean } & React.ComponentProps<
    typeof motion.div
  >) {
  return (
    <motion.div
      {...(!isFullScreen && {
        initial: { y: "-100%" },
        animate: { y: isHovered ? 0 : "-100%" },
      })}
      {...props}
      css={[
        {
          position: "absolute",
          top: "0",
          right: "0",
          padding: isFullScreen ? "1em" : "0.5em",
          zIndex: 9999,
        },
        (props as any).css,
      ]}
    >
      <CloseOverlayButton
        isFullScreen={isFullScreen}
        toggleFullScreen={toggleFullScreen}
      />
    </motion.div>
  );
}

type EndCallOverlayProps = {
  isVideo: boolean;
  numberDialed: string;
  handleClose: () => void;
};

export function EndCallOverlay({
  isVideo,
  numberDialed,
  handleClose,
}: EndCallOverlayProps) {
  const setDisplayContact = useSetAtom(atoms.contacts.displayContact);
  const { callWithAudio, callWithVideo } = useCall();
  const navigate = useNavigate();
  const contacts = useContacts();

  let contact = contacts?.findWithNumber(numberDialed);
  const hasContact = !!contact;
  if (!hasContact) {
    contact = WebGwContact.fromPhoneNumber(numberDialed);
  }

  const handleSelectConversation = () => {
    if (!numberDialed) {
      return;
    }

    setSelectedConversationId(
      Conversation.getOrCreate({
        phoneNumber: numberDialed,
        contactToLinkIfCreate: contact,
      }).conversation.id
    );
    handleClose();
    navigate(paths.messages);
  };

  const handleNavigateToContact = () => {
    if (contact) {
      setDisplayContact(contact);
      navigate(paths.contacts);
    }
    handleClose();
  };

  const handleMakeCall = async () => {
    handleClose();

    if (isVideo) {
      callWithVideo(numberDialed);
    } else {
      callWithAudio(numberDialed);
    }
  };

  const callIcon = isVideo ? <VideocamIcon /> : <PhoneIcon />;

  return (
    <SmallOverlay>
      <div
        css={{
          width: "fit-content",
          height: "fit-content",
          backgroundColor: colors.primaryBackground,
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          color: colors.primaryTextColor,
          padding: "3vw",
        }}
      >
        <div
          css={{ display: "flex", justifyContent: "end", width: "100%" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </div>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "1vh",
            alignItems: "center",
          }}
        >
          <IncomingContactAvatar contact={contact!} />
          <div>Call Ended</div>
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "1vh",
            width: "40vw",
            maxWidth: "30em",
            paddingTop: "10vh",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              gap: "1vh",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              css={[
                EndCallButtons,
                {
                  backgroundColor: colors.secondaryAccentColor,
                },
              ]}
              onClick={handleMakeCall}
            >
              {callIcon} Call Again
            </button>
            <button
              css={EndCallButtons}
              onClick={
                hasContact ? handleNavigateToContact : handleSelectConversation
              }
            >
              <PersonOutlineIcon />
              {hasContact ? "View Contact Card" : "Send a Message"}
            </button>
          </div>
          {hasContact && (
            <div
              css={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button css={EndCallButtons} onClick={handleSelectConversation}>
                Send a Message
              </button>
            </div>
          )}
        </div>
      </div>
    </SmallOverlay>
  );
}
