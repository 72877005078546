import { proxy } from "valtio";
import { derive, devtools, proxyMap } from "valtio/utils";
import type Call from "./Call";

declare module "valtio" {
  function useSnapshot<T extends object>(p: T): T;
}

export type CallId = string;
export type CallMap = Map<CallId, Call>;

const lastSelectedCallIdKey = "lastSelectedCallId";

let _selectedCallId = localStorage.getItem(lastSelectedCallIdKey) || null;
export const callsState = proxy({
  get selectedCallId() {
    if (_selectedCallId && !this.calls.has(_selectedCallId)) {
      return null;
    }
    return _selectedCallId;
  },
  set selectedCallId(val: string | null) {
    _selectedCallId = val;
    if (val == null) {
      localStorage.removeItem(lastSelectedCallIdKey);
    } else {
      localStorage.setItem(lastSelectedCallIdKey, val);
    }
  },
  calls: proxyMap(new Map()) as CallMap,
  mapVersion: 0,
});

if (import.meta.env.DEV && "__REDUX_DEVTOOLS_EXTENSION__" in window) {
  const debugCallState = derive({
    ...Object.assign(
      // @ts-ignore
      ...Object.keys(callsState).map((key) => ({
        // @ts-ignore
        [key]: (get) => get(callsState)[key],
      }))
    ),
    calls: (get) => Object.fromEntries(get(callsState).calls),
  });

  devtools(debugCallState);
}

export function setCalls(newCalls: typeof callsState.calls) {
  // callsState.calls = proxyMap(new Map(newCalls));
  newCalls.forEach((c, id) => {
    // ? not sure why cloning the messages is necessary to reflect an update
    callsState.calls.set(id, c);
  });
  callsState.mapVersion++;
}
