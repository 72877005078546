import { CallInfos } from "@/types/calling";
import { CapabilitiesPerNumber } from "@/types/capabilities";
import { DeviceIdentityData } from "@/types/common";
import { Filters } from "@/types/contacts";
import { Reply } from "@/types/messaging";
import { CategoryKey } from "@/types/odience";
import { atom, getDefaultStore, WritableAtom } from "jotai";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomWithReset, atomWithStorage, RESET } from "jotai/utils";
import {
  filterContactWithCaps,
  getContactsAsync,
  getLoadedContacts,
} from "../contacts/contactUtils";
import Conversation from "../messaging/conversation/Conversation";
import { queryUserLocationCountry } from "../queries/common";
import { queryConfig } from "../queries/config";
import { defaultChatbotImgBackgroundColorPromise } from "./chatbots";
import WebGwContact, { WebGwContactList } from "./WebGwContact";

const _contactFilter = atomWithReset<Filters>("all");

export const atoms = {
  calling: {
    outgoingCallInfos: atomWithReset<CallInfos | undefined>(undefined),
    callActive: atomWithReset(false),
    incomingCallInfos: atomWithReset<CallInfos | undefined>(undefined),
    callFailedError: atomWithReset<string | undefined>(undefined),
    callMuted: atomWithReset(false),
    selectedCallLogs: atomWithReset<string[]>([]),
    selectedCallLogsCheckboxes: atomWithReset<string[]>([]),
    checkAvailableHardware: atomWithReset(false),
  },
  messaging: {
    messageReply: atomWithReset<Reply | null>(null),
    audioPlaying: atomWithReset<HTMLAudioElement | undefined>(undefined),
    sendBtnDisabled: atomWithReset(true),
    showNewMessageNotification: atomWithReset(false),
    currentMessageNotificationSender: atomWithReset(""),
    previousMessageNotificationSender: atomWithReset(""),
    keepPreviousMessageNotificationChatOverlay: atomWithReset(false),
    autoOpenCurrentMessageNotification: atomWithReset(0),
    selectedConversations: atomWithReset<Conversation[]>([]),
    selectedConversationsCheckboxes: atomWithReset<string[]>([]),
    callTranscriptText: atomWithReset(""),
    callTranscriptRemoteSender: atomWithReset(""),
    disableIncomingNotificationForAddress: atomWithReset(""),
  },
  odience: {
    privateEventPopup: atomWithReset(false),
    webOnlyEvents: atomWithStorage("webOnlyEvents", true),
    selectedCategory: atomWithReset<CategoryKey>("all"),
    isVideoWallCall: atomWithReset(false),
    usePreviewFrame: atomWithReset(""),
    videoWallJoined: atomWithReset(false),
    featuredCaller: atomWithReset(false),
    mutedByModerator: atomWithReset(false),
    streamVolume: atomWithReset(100),
    streamMicVolume: atomWithReset(0),
    fromReload: atomWithStorage("refresh", false),
    isFirstLoad: atomWithStorage("isFirstLoad", true),
    messageToSend: atomWithReset(""),
  },
  chatbot: {
    defaultChatbotImgBackgroundColor: atom(
      () => defaultChatbotImgBackgroundColorPromise
    ),
    selectedChatbot: atomWithReset<WebGwContact | undefined>(undefined),
  },
  user: {
    userLocationCountry: atomWithSuspenseQuery(
      (get) => queryUserLocationCountry
    ),
  },
  contacts: {
    contactFilter: atom(
      (get) => get(_contactFilter),
      async (_get, set, filter: Filters | typeof RESET) => {
        const contacts = await getContactsAsync();
        const verseContacts = filterContactWithCaps(contacts);

        switch (filter) {
          case "verse":
            set(_contactFilter, filter);
            set(atoms.contacts.filteredContacts, verseContacts);
            break;
          case "all":
          case RESET:
          default:
            set(_contactFilter, filter);
            set(atoms.contacts.filteredContacts, contacts);
        }
      }
    ),
    filteredContacts: atomWithReset<WebGwContactList | null | undefined>(
      undefined
    ),
    numberOfFilteredContacts: atomWithReset(1),
    displayContact: atomWithReset<WebGwContact | null>(null),
    blockedList: atomWithReset<string[] | undefined>(undefined),
  },
  capabilities: {
    capabilities: atomWithReset<CapabilitiesPerNumber>({}),
  },
  provisioning: {
    result: atomWithReset(false),
    user: atomWithReset(""),
    devices: atomWithReset<DeviceIdentityData[] | undefined>(undefined),
    maxDevices: atomWithReset(""),
    isLoggedIn: atomWithReset(false),
    reconnectingAtom: atomWithReset(false),
  },
  common: {
    config: atomWithSuspenseQuery((get) => queryConfig),
  },
};

const isWritableAtom = <Value, Args, Result>(
  atom: any
): atom is WritableAtom<Value, Args[], Result> =>
  atom && typeof atom.write === "function";

const defaultStore = getDefaultStore();
atoms.contacts.filteredContacts.onMount = (set) => {
  const contacts = getLoadedContacts();
  if (contacts) {
    set(contacts);
  } else {
    defaultStore.set(atoms.contacts.contactFilter, "all");
  }
};

export const resetAtoms = () => {
  Object.values(atoms)
    .flatMap((atom) => Object.values(atom))
    .filter(isWritableAtom)
    .forEach((value) => {
      defaultStore.set(value, RESET);
    });
};
